/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.hide {
  display: none;
}

/* Intro Page */
@media (min-width: 0px) {
  .intro-center {
    position: fixed;
    top: 45%;
    padding: 30px;
    transform: translate(-0%, -50%);
  }
}
@media (min-width: 500px) {
  .intro-center {
    position: fixed;
    top: 45%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
  }
}
.intro-title {
  color: white;
  max-width: 550px;
  text-align: center;
  font-size: 26px;
  margin-top: 30px;
}

.intro-description {
  max-width: 550px;
  text-align: center;
  font-size: 16px;
  color: white;
}

.intro-button {
  background-color: rgba(114, 87, 130) !important;
  color: white !important;
  border-color: rgba(114, 87, 130) !important;
  border-style: solid !important;
  border-width: 2px 2px 2px 2px !important;
  width: 200px;
  margin-top: 25px;
}

/* Banner Section */
.prolink-top-contacts {
  background-color: black;
  color: white;
  height: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
}

.prolink-header-logo {
  margin: 8px;
}
.prolink-header-logo img {
  width: 140px !important;
}

.prolink-main-banner-title {
  font-family: "Nunito Sans", Sans-serif;
  font-size: 70px;
  font-weight: 700;
  line-height: 1.1em;
}

.prolink-left-padding {
  margin-left: 10px !important;
}

.elementor-4708
  .elementor-element.elementor-element-3a8c5ca:not(.elementor-motion-effects-element-type-background),
.elementor-4708
  .elementor-element.elementor-element-3a8c5ca
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  /* background-image: url("./images/lighthouse.jpg"); */
  background-image: url("./images/shutterstock-2183020827.jpg");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Banner area */
/*rgba(222,136,140) = pink*/
.prolink-button {
  background-color: rgba(114, 87, 130) !important;
  color: white !important;
  border-color: rgba(114, 87, 130) !important;
  border-style: solid !important;
  border-width: 2px 2px 2px 2px !important;
}

.transparent-button {
  background-color: white !important;
  color: rgba(114, 87, 130) !important;
  border-color: white !important;
  border-style: solid !important;
  border-width: 2px 2px 2px 2px !important;
}

.elementor-4708 .elementor-element.elementor-element-8ab18e5 {
  --divider-border-style: solid;
  --divider-border-color: rgba(222, 136, 140);
  --divider-border-width: 1px;
  width: auto;
  max-width: auto;
  margin-left: 10px !important;
}

.prolink-banner-container {
  min-height: 650px !important;
  margin-top: -60px;
}
.prolink-banner-button {
  margin-bottom: 10px;
  margin-right: 20px;
}
.prolink-banner-logos-container {
  padding-top: 160px;
  min-height: 50px !important;
}
/* @media (max-width: 767px) {
  .prolink-banner-logos-column {
    width: 50% !important;
  }
}
@media (max-width: 767px) {
  .prolink-banner-logos-column {
    width: 50% !important;
  }
} */
.prolink-banner-logos-left {
  float: left;
  display: inline-flex;
}
.prolink-banner-logos-left img {
  max-height: 70px !important;
  margin: 10px 15px 10px 15px;
  float: left;
}
.prolink-banner-logos-right {
  float: right;
  display: inline-flex;
}
.prolink-banner-logos-right img {
  max-height: 80px;
  /* max-height: 50px !important; */
  margin: 10px 15px 10px 15px;
  float: right;
}
/* 20241024 Added */
.prolink-banner-logos-right-cpa {
  max-height: 70px !important;
}
.prolink-banner-logos-right-ipa {
  max-height: 70px !important;
}
.prolink-banner-logos-right-taxagent {
  max-height: 80px !important;
}
/* --- */
@media (max-width: 767px) {
  .prolink-banner-logos-left img {
    max-height: 70px !important;
    margin: 30px 15px 10px 15px;
    float: left;
  }
  .prolink-banner-logos-right img {
    max-height: 50px !important;
    margin: 30px 15px 10px 15px;
    float: right;
  }
}
.prolink-inline {
  display: inline !important;
  margin-top: 2%;
}
.elementor-4708 .elementor-element.elementor-element-5c4e94e {
  margin-top: -190px;
}
/* Who we are */
.prolink-who-we-are-text {
  color: rgba(114, 87, 130) !important;
}

/* Glowing Text */

@media (min-width: 0px) {
  .prolink-glowing-text {
    font-size: 35px !important;
    font-weight: 500 !important;
  }
  .prolink-glowing-text-container-row-one {
    margin: 80px 0px 0px 10px !important;
  }
  .prolink-glowing-text-container-row-two {
    margin: 10px 10px 0px 0px !important;
  }
}
@media (min-width: 600px) {
  .prolink-glowing-text {
    font-size: 60px !important;
    font-weight: 500 !important;
  }
  .prolink-glowing-text-container-row-one {
    margin: 80px 0px 0px 10px !important;
  }
  .prolink-glowing-text-container-row-two {
    margin: 10px 10px 0px 0px !important;
  }
}

@media (min-width: 1200px) {
  .prolink-glowing-text-container-row-one {
    margin: 80px 0px 0px 100px !important;
  }
  .prolink-glowing-text-container-row-two {
    margin: 10px 100px 0px 0px !important;
  }
}
.elementor-4708 .elementor-element.elementor-element-5581ca8 {
  padding: 100px 0px 30px 0px !important;
}
.elementor-4708
  .elementor-element.elementor-element-25db8db
  .elementor-heading-title {
  color: rgb(255, 255, 255) !important;
  text-shadow: 5px 5px 10px rgb(157, 11, 241) !important;
}
.elementor-4708
  .elementor-element.elementor-element-39bf6aa
  .elementor-heading-title {
  color: rgb(255, 255, 255) !important;
  text-shadow: 5px 5px 10px rgb(157, 11, 241) !important;
}
/* We Provide */
.elementor-4708
  .elementor-element.elementor-element-5ac6c81
  > .elementor-widget-container.prolink-we-provide
  .elementor-heading-title {
  font-family: "Poppins", Sans-serif;
  font-size: 24px;
  line-height: 1.3em;
  font-weight: 600;
  margin-top: 40px;
  text-align: left;
}

.elementor-4708
  .elementor-element.elementor-element-5ac6c81
  > .elementor-widget-container.prolink-we-provide {
  margin: 0px 10px 0px 10px !important;
  padding: 0% 0% 0% 0%;
}

.prolink-mid-image-container {
  margin-top: 30px !important;
}

/* Proferssional Services */
.prolink-services-diagram {
  margin-left: 10%;
  margin-right: 5%;
}
.prolink-services-title {
  font-size: 36px !important;
  text-align: center;
  margin-bottom: 30px !important;
}

#prolink-services-middle-top {
  margin-top: -7%;
  margin-left: -7%;
  margin-bottom: -7%;
  margin-right: -7%;
}

#prolink-services-top-middle {
  margin-bottom: 3%;
}
#prolink-services-top-left {
  margin-left: -5%;
}


#prolink-services-middle {
  margin-top: 5%;
  margin-bottom: 3%;
}
#prolink-services-middle-left {
  margin-left: -5%;
}

#prolink-services-bottom-left {
  margin-left: 8%;
}
#prolink-services-middle-bottom {
  margin-right: -25%;
}


@media (max-width: 768px) {
  .elementor-column.elementor-col-60,
  .elementor-column[data-col="60"] {
    width: 100% !important;
  }
  .elementor-column.elementor-col-40,
  .elementor-column[data-col="40"] {
    width: 100% !important;
  }
}

.prolink-service-title {
  margin-top: 30px;
  text-align: left;
  color: rgba(222, 136, 140);
  font-size: 22px;
  font-weight: 700;
  border-bottom: 2px solid black;
  width: auto;
  float: left;
  display: block;
}
.prolink-service-box {
  margin-left: 15px;
}
.prolink-service-item {
  text-align: left;
  padding-top: 15px;
  font-size: 18px;
}
.prolink-service-item i {
  color: rgba(222, 136, 140);
  margin-right: 10px;
}
/* OUR TEAM */
.prolink-negative-margin {
  margin-left: -30px !important;
}
.elementor-5733
  .elementor-element.elementor-element-4c92694
  > .elementor-background-overlay {
  background-color: rgb(242, 242, 242);
  opacity: 0.5;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.prolink-meet-our-team-container {
  padding-top: 80px;
}

.prolink-meet-our-team {
  text-align: center;
  font-weight: 700 !important;
}

.prolink-meet-our-team-subtitle {
  text-align: center !important;
  padding: 0px !important;
  font-size: 18px !important;
}

.prolink-team-name-center {
  color: rgba(114, 87, 130) !important;
  text-align: center;
  font-size: 20px;
  font-weight: 700 !important;
  margin-bottom: 30px;
  font-family: "Poppins", Sans-serif;
}

.prolink-team-name {
  color: rgba(114, 87, 130) !important;
  font-size: 20px;
  font-weight: 700 !important;
  margin-bottom: 20px;
  font-family: "Poppins", Sans-serif;
}

/* HISTORY */
.prolink-history-title {
  font-size: 36px;
  text-align: center;
  font-weight: 700 !important;
}

.prolink-history-subtitle {
  text-align: center !important;
  margin-top: 20px !important;
  font-size: 18px;
  color: #7e8090;
}
.cd-horizontal-timeline .filling-line {
  background-color: rgba(114, 87, 130);
}
.cd-horizontal-timeline .events a.selected::after {
  background-color: rgba(114, 87, 130);
  border-color: rgba(114, 87, 130);
}
.cd-horizontal-timeline .events a.older-event::after {
  border-color: rgba(114, 87, 130);
}

/* HISTORY (Vertical) */




/* HIST-TIMELINE */

.hist-timeline-container{
  width: 100%;
  height: auto;
  position: relative;
  margin-left: 5%;
  margin-right: 5%;
}

.hist-timeline-container::after{
  position: absolute;
  content: ' ';
  width: 2.5px;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(114, 87, 130), rgb(181, 155, 196));
}

.hist-timeline-container ul{
  list-style: none;
}

/* z-index and relative to send the vertical line back */
.hist-timeline-container ul li{
  width: 50%;
  height: auto;
  padding: 15px 20px;
  background-color: #fff;
  box-shadow: 1px 1px 15px #969696;
  border-radius: 10px;
  margin-bottom: 30px;
  z-index: 99;
  position: relative;
}

.hist-timeline-container ul li:nth-child(odd){
  float: left;
  clear: right;
  transform: translateX(-30px);
  text-align: right;
}

.hist-timeline-container ul li:nth-child(odd) .date{
  right: 0px;
}

.hist-timeline-container ul li:nth-child(even){
  float: right;
  clear: left;
  transform: translateX(30px);
}

.hist-timeline-container ul li:nth-child(even) .date{
  left: 0px;
}

.hist-timeline-container ul li:nth-child(9){
  margin-bottom: 0;
}

.hist-timeline-container ul li .title{
  font-size: 20px;
  font-weight: 500;
  color: rgba(114, 87, 130);
}

.hist-timeline-container ul li p{
  font-size: 15px;
  color: rgb(97, 97, 97);
  margin: 5px 0;
  text-decoration: none;
}

.hist-timeline-container ul li .date{
  position: absolute;
  top: -40px;
  /* left: 0px; */
  width: 135px;
  height: 35px;
  border-radius: 20px;
  color: #fff;
  /* background-image: linear-gradient(to right, rgba(114, 87, 130), rgb(220, 0, 240)); */
  background-color: rgb(114, 87, 130);
  display: grid;
  place-items: center;
  font-size: 18px;
}

.hist-timeline-container ul li .circle{
  width: 30px;
  height: 30px;
  background-color: rgb(220, 0, 240, 0.4);
  border-radius: 50%;
  position: absolute;
  top: 0;
}

.hist-timeline-container ul li .circle::after{
  content: ' ';
  width: 15px;
  height: 15px;
  background-color: rgba(114, 87, 130);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hist-timeline-container ul li:nth-child(odd) .circle{
  right: -30px;
  transform: translate(55%, -55%);
}

.hist-timeline-container ul li:nth-child(even) .circle{
  left: -30px;
  transform: translate(-45%, -45%);
}




/* CONTACT US */
.elementor-5879
  .elementor-element.elementor-element-172e078
  > .elementor-container {
  min-height: 500px;
}

.prolink-contact-us-banner-purple {
  color: rgba(114, 87, 130) !important;
}

.prolink-contact-us-banner-description {
  color: white;
  text-align: center;
  font-size: 18px;
}
.prolink-contact-us-banner-element {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}
.prolink-contact-us-submit-button {
  background-color: rgba(114, 87, 130) !important;
  border: rgba(114, 87, 130) !important;
  border-width: 2px !important;
  color: white;
  height: 50px;
}
.prolink-contact-us-submit-button:hover {
  background: rgba(222, 136, 140) !important;
  color: white !important;
  border: rgba(114, 87, 130) !important;
  border-width: 2px !important;
}
.prolink-contact-us-banner-button:hover {
  background-color: rgba(222, 136, 140) !important;
  border-color: rgba(222, 136, 140) !important;
}

/* Blog Banner - Latest News */
.prolink-blog-banner-title {
  color: white !important;
}

.elementor-4309
  .elementor-element.elementor-element-46f696e:not(.elementor-motion-effects-element-type-background),
.elementor-4309
  .elementor-element.elementor-element-46f696e
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-image: url("./images/prolink-blog-banner-bg1.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 120px !important;
  padding-bottom: 120px !important;
}

.elementor-4309 .elementor-element.elementor-element-421022c a.elementor-button,
.elementor-4309 .elementor-element.elementor-element-421022c .elementor-button {
  font-weight: 700;
  fill: #163967;
  color: white;
  background-color: rgba(114, 87, 130);
  border-radius: 5px 5px 5px 5px;
  border-color: rgba(114, 87, 130);
}

.elementor-4309
  .elementor-element.elementor-element-421022c
  a.elementor-button:hover,
.elementor-4309
  .elementor-element.elementor-element-421022c
  .elementor-button:hover,
.elementor-4309
  .elementor-element.elementor-element-421022c
  a.elementor-button:focus,
.elementor-4309
  .elementor-element.elementor-element-421022c
  .elementor-button:focus {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0);
  border-color: white;
}

/* Resources */
.resource-form-tabs {
  margin-top: 30px;
  padding-top: 0px;
  padding-bottom: 0px;
}

#resources .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: rgba(114, 87, 130);
  font-size: 18px;
  font-weight: bold;
}
#resources .nav-tabs .nav-link,
.nav-tabs .nav-item.show .nav-link {
  color: black;
  font-size: 18px;
}

.resource-form-tab-container {
  border-width: 0px 1px 1px 1px !important;
  border-style: solid;
  border-color: rgb(230, 229, 229) !important;
  background-color: white;
  padding: 50px 40px;
  margin-bottom: 70px;
}

.resource-form-tab-container h1 {
  font-size: 32px;
  color: rgb(73, 71, 71);
  margin-left: 10px;
  margin-bottom: 20px;
}
.resource-form-tab-container h1 span {
  color: rgb(114, 87, 130);
}
.resource-form-tab-container i {
  color: rgb(222, 136, 140);
  padding: 10px;
}
.resource-form-tab-container a {
  color: rgb(147, 147, 147);
}
.resource-form-tab-container img {
  padding: 10px;
}
#resources {
  padding-top: 60px;
}
#resources h2 {
  font-size: 36px;
}

/* Contact Us */
.prolink-contact-us-user-message {
  width: 100%;
  margin: 5px auto;
  text-align: center;
  color: rgb(222, 136, 140);
}
